<template>
  <v-app>
    <div style="height:108px;box-shadow: 0 6px 8px rgba(20,19,19,0.04);">
    <v-navigation-drawer
      right
      v-model="drawer"
      app
      temporary
    >
      <v-list dense subheader class="ctk-wap-menu">
        <v-list-item to="/">
          <v-list-item-content>
            <v-list-item-title>首页</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>公司介绍</v-subheader>
        <v-list-item to="/about">
          <v-list-item-content>
            <v-list-item-title>公司简介</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/culture">
          <v-list-item-content>
            <v-list-item-title>理念和宗旨</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/products" class="ctk-border-top">
          <v-list-item-content>
            <v-list-item-title>产品中心</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/cases" class="ctk-border-top">
          <v-list-item-content>
            <v-list-item-title>项目案例</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/quality" class="ctk-border-top">
          <v-list-item-content>
            <v-list-item-title>关于品质</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/news" class="ctk-border-top">
          <v-list-item-content>
            <v-list-item-title>新闻动态</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>联系我们</v-subheader>
        <v-list-item to="/contact">
          <v-list-item-content>
            <v-list-item-title>联系方式</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/hr">
          <v-list-item-content>
            <v-list-item-title>企业招聘</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app flat absolute class="app-bar" height="108" max-width="1264">
      <v-toolbar-title class="headline" style="display: flex;align-items: center;">
        <a href="/"><img :src="info.logo" style="height:60px;"/></a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <v-flex style="clear: both;">
      <v-toolbar-items>
        <v-btn id="menu_home" href="/" class="ml-0 hidden-sm-and-down white text-none ctk-menu">首页</v-btn>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn id="menu_about" v-on="on" class="white text-none hidden-sm-and-down" >公司介绍</v-btn>
          </template>
          <v-list>
            <v-list-item href="/about">
              <v-list-item-title>公司简介</v-list-item-title>
            </v-list-item>
            <v-list-item href="/culture">
              <v-list-item-title>理念和宗旨</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn id="menu_products" href="/products" class="ml-0 hidden-sm-and-down white text-none ctk-menu">产品中心</v-btn>
        <v-btn id="menu_cases" href="/cases" class="ml-0 hidden-sm-and-down white text-none ctk-menu">项目案例</v-btn>
        <v-btn id="menu_quality" href="/quality" class="ml-0 hidden-sm-and-down white text-none ctk-menu">关于品质</v-btn>
        <v-btn id="menu_news" href="/news" class="ml-0 hidden-sm-and-down white text-none ctk-menu">新闻动态</v-btn>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn id="menu_contact" v-on="on" class="white text-none hidden-sm-and-down nav_menu" >联系我们</v-btn>
          </template>
          <v-list>
            <v-list-item href="/contact">
              <v-list-item-title>联系方式</v-list-item-title>
            </v-list-item>
            <v-list-item href="/hr">
              <v-list-item-title>招聘岗位</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click.stop="drawer = !drawer"
        >
        </v-app-bar-nav-icon>
      </v-toolbar-items>
        </v-flex>
      </div>
    </v-app-bar>
    </div>
    <v-main>
<!--      <router-view></router-view>-->
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    Footer: () => import('@/components/Footer')
  },
  data: () => ({
    keywords: '',
    afterGetData: false,
    drawer: null,
    languages: ['简体中文', 'English']
  }),
  mounted () {
    this.$store.dispatch('loadInfo')
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        // this.info.logo = val.logo
        document.querySelector('meta[name="keywords"]').setAttribute('content', val.seo_keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', val.seo_description)
        document.querySelector('link[rel*=\'icon\']').setAttribute('href', val.favicon)
      },
      deep: true
    },
    '$route' (to, from) {
      this.title(this.$route)
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    /**
     * 路由切换设置title
     * @param to
     */
    title (to) {
      // document.title = to.meta.title + '_' + this.$store.getters.companyInfo.name
    }
  }
}
</script>
<style lang="scss">
  @import 'sass/variables.scss';
  .v-application {
    font-family: $body-font-family !important;
  }
</style>
<style lang="css">
  @import 'css/public.css';
</style>
