import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { title: '首页', keywords: '', description: '' }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
      meta: { title: '公司介绍', keywords: '', description: '' }
    },
    {
      path: '/culture',
      name: 'culture',
      component: () => import('./views/Culture.vue'),
      meta: { title: '企业文化', keywords: '', description: '' }
    },
    {
      path: '/news/:type(\\d+)?',
      name: 'news',
      component: () => import('./views/News.vue'),
      meta: { title: '新闻', keywords: '', description: '' }
    },
    {
      path: '/new/:id(\\d+)',
      name: 'newsDetail',
      component: () => import('./views/New.vue'),
      meta: { title: '新闻详情', keywords: '', description: '' }
    },
    {
      path: '/products/:cid(\\d+)?',
      name: 'products',
      component: () => import('./views/Products.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/cases',
      name: 'cases',
      component: () => import('./views/Cases.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/case/:id(\\d+)',
      name: 'caseDetail',
      component: () => import('./views/CaseDetail.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/quality',
      name: 'quality',
      component: () => import('./views/Quality.vue'),
      meta: { title: '关于品质', keywords: '', description: '' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue'),
      meta: { title: '联系我们', keywords: '', description: '' }
    },
    {
      path: '/hr',
      name: 'hr',
      component: () => import('./views/Hr.vue'),
      meta: { title: '人才招聘', keywords: '', description: '' }
    }
  ]
})
